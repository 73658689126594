import Vue from "vue";
import App from "./AppContainer.vue";
import router from "./router";
import store from "./store";
import VueFab from "vue-float-action-button";
import "../node_modules/timeline-vuejs/dist/timeline-vuejs.css";
import PrimeVue from "primevue/config";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "intro.js/minified/introjs.min.css";
import Keycloak from "keycloak-js";
import '@/assets/main.css'
let initOptions = {
  url: process.env.VUE_APP_URL_SSO,
  realm: process.env.VUE_APP_REALM_SSO,
  clientId: process.env.VUE_APP_CLIENT_ID_SSO,
};
 window.keycloak = Keycloak(initOptions);
import "@/plugin/keycloak";
Vue.use(Toast);
Vue.use(PrimeVue);
// Vue.config.productionTip = false

Vue.use(VueFab, {
  // opitons 可选iconfont图标或MaterialIcons
  iconType: "MaterialDesign",
  // iconType: 'iconfont'
});
if (window.location.pathname === '/link') {
  // window.keycloak.init({
  //   onLoad: 'login-required',
  // }).then(_auth => console.log('2', _auth))
  window.keycloak.init({
    onLoad: 'check-sso'
  }).then(_ => window.keycloak.login({
    redirectUri : `${window.location.origin}/linked${window.location.search}`
  }))
} else {
// const isReq
  window.keycloak.init({
      onLoad: 'check-sso',
      token : localStorage.token,
      refreshToken: localStorage.refreshToken ,
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    }).then (auth => {
      window.auth = auth
      window.keycloak.onAuthLogout = () => {
        localStorage.clear()
        window.location.reload()
      }
      window.keycloak.onTokenExpired = () => {
        if(localStorage.token) {
          window.keycloak.token = localStorage.token
          window.keycloak.refreshToken = localStorage.refreshToken
        }
          window.keycloak.updateToken(-1).success(() => {
            localStorage.setItem('token', window.keycloak.token)
            window.token = window.keycloak.token
            localStorage.setItem('refreshToken', window.keycloak.refreshToken)
          }).error(() => {});
      }
      if(auth) {
        window.keycloak.onTokenExpired()
        localStorage.setItem('token', window.keycloak.token)
        localStorage.setItem('refreshToken', window.keycloak.refreshToken)
        window.token = window.keycloak.token
      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
      }
      new Vue({
              router,
              store,
              render: h => h(App)
            }).$mount('#app')
    })
}
